<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Pages <span>Page User Action</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" class="page-media-headline-setting-form">
                <div class="modal_body pt-4">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display CTA Button</h5>
                            <label for="show_cta_btn_edit" class="switch_option capsule_btn border-0 m-0">
                                <input type="checkbox" id="show_cta_btn_edit" true-value="display" false-value="hide" v-model="form.cta_button.display" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-if="form.cta_button.display == 'display'">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Button Text</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.text }">
                                        <Field autocomplete="off" type="text" name="text" v-model="form.cta_button.text" placeholder="ex: Click Here" rules="required" />
                                    </div>
                                    <ErrorMessage name="text" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Button Color</label>
                                    <Field autocomplete="off" name="cta_button_btn_color" v-model="form.cta_button.btn_color" type="text" label="button color">
                                        <color-picker v-model="form.cta_button.btn_color" :classes="{ 'has-error': errors.cta_button_btn_color }" />
                                    </Field>
                                    <ErrorMessage name="cta_button_btn_color" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Button Text Color</label>
                                    <Field autocomplete="off" name="cta_button_btn_text_color" v-model="form.cta_button.btn_text_color" type="text" label="button text color">
                                        <color-picker v-model="form.cta_button.btn_text_color" :classes="{ 'has-error': errors.cta_button_btn_text_color }" />
                                    </Field>
                                    <ErrorMessage name="cta_button_btn_text_color" class="text-danger" />
                                </div>
                            </div>
                            <h3 class="sub_header mt-5">Button Position</h3>
                            <div class="edit_section">
                                <div class="capsule_elm border-bottom">
                                    <h5>Left</h5>
                                    <label for="left" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="left" value="left" v-model="form.cta_button.position" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="capsule_elm border-bottom">
                                    <h5>Center</h5>
                                    <label for="center" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="center" value="center" v-model="form.cta_button.position" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="capsule_elm">
                                    <h5>Right</h5>
                                    <label for="right" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="right" value="right" v-model="form.cta_button.position" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <h3 class="sub_header mt-5">Button Action</h3>
                            <div class="edit_section">
                                <div class="capsule_elm border-bottom">
                                    <h5>Redirect</h5>
                                    <label for="redirect" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="redirect" value="redirect" v-model="form.cta_button.option" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="capsule_elm">
                                    <h5>Popup</h5>
                                    <label for="popup" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="popup" value="popup" v-model="form.cta_button.option" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <div v-show="form.cta_button.option == 'redirect'">
                                <div class="form_grp mt-3">
                                    <div class="group_item">
                                        <label class="input_label">Select link</label>
                                        <div class="field_wpr">
                                            <multiselect
                                                v-model="link"
                                                :groups="true"
                                                :searchable="true"
                                                :options="links"
                                                valueProp="id"
                                                label="title"
                                                placeholder="Select link"
                                                :groupSelect="false"
                                                @select="handleLinkSelect"
                                                :groupHideEmpty="true"
                                            >
                                                <template v-slot:option="{ option }">
                                                    <span v-if="option.$isLabel">
                                                        {{ option.$groupLabel }}
                                                    </span>
                                                    <span v-else>{{ option.title }}</span>
                                            </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Redirect Link</label>
                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors.url }">
                                            <Field autocomplete="off" type="text" name="url" v-model="form.cta_button.url" placeholder="https://superfitcoaching.com" rules="url" />
                                            <span class="prefix">URL</span>
                                        </div>
                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                        <ErrorMessage name="url" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp mb-2 offer-setting" v-show="form.cta_button.option == 'popup'">
                                <p><small>* See page offer section for settings</small></p>
                                <p><small>* If popup was off in page offer section. Call to action button will override the configuration and will show the popup dialog when clicking the button</small></p>
                                <button type="button" class="btn save_btn mt-2" @click="toggleOfferSetting()">Show</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="pageUpdateLoader" class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                    <button :disabled="pageUpdateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="pageUpdateLoader"></i> {{ pageUpdateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))

    export default {
        name: 'Page Action Button',

        data () {
            return {
                link: '',
                form: {
                    cta_button: {}
                },
            };
        },

        props: {
            modelValue: Boolean,
            toggleOfferSetting: Function,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ColorPicker,
        },

        watch: {
            modelValue () {
                const vm = this;

                vm.resetForm();
            },
        },

        computed: mapState({
            pageUpdateLoader: state => state.pageModule.pageUpdateLoader,
            selectedPage: state => state.pageModule.selectedPage,
            links: state => state.commonModule.definedLinks,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePage: 'pageModule/updatePage',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    page_id: vm.selectedPage.id,
                    cta_button: vm.selectedPage.cta_button ? JSON.parse(JSON.stringify(vm.selectedPage.cta_button)) : {},
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.form));

                params.setFieldError = setFieldError;

                vm.updatePage(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },

            handleLinkSelect (id, link) {
                const vm = this;

                vm.link  = link.id;
                vm.form.cta_button.url  = link.public_url;
            },
        },
    }
</script>

<style scoped>
    :deep(.page-media-headline-setting-form) {
        height: calc(100vh - 180px);
    }

    :deep(.page-media-headline-setting-form .group_item) {
        width: 100%;
    }

    :deep(.form_grp .group_item label.input_label) {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }

    :deep(.offer-setting) {
        padding: 5px;
        margin-top: 20px;
    }

    :deep(.offer-setting .save_btn) {
        width: 125px;
        height: 30px;
        border: 1px solid #2F80ED;
        background: #2F80ED;
        border-radius: 4px;
        font-size: 15px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        padding: 0;
        box-sizing: border-box;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
</style>
